import http from '@/libs/http'
export default class RegimenService {
  getRegimen () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/listar_regimen`)
  }

  get () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/regimen`)
  }

  getRF () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/regimenF`)
  }
}
