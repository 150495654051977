<template>
    <section>
      <div class="lg:flex mt-4">
        <div class="w-full p-4 bg-white rounded-lg">
            <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 my-3 items-center mx-6">
                <div class="hidden xl:flex">
                  <div v-if="$can('pharmasan.ventas.facturacion.modificacion-cantidad-ov-por-json')">
                    <Button icon="pi pi-cog" @click="displayNumeroOrdenesJson = true" class="p-button-rounded p-button-text" />
                  </div>
                </div>
                <div class="block xl:grid text-center place-content-center mt-2">
                    <div class="boxed-tabs nav nav-tabs inline-flex rounded-md shadow-sm border bg-white border-gray-200" role="group">
                        <router-link v-for="(item, index) in items" :key="index"
                                :id="item.id"
                                :to="item.to"
                                :class="item.class"
                                class="lg:w-40 text-sm btn-des-right grid place-content-center inline-flex items-center py-2 px-4 text-sm font-medium text-blue-800 bg-white rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:rounded-r-lg focus:z-10 focus:ring-blue-700 focus:text-white focus:bg-blue-800 dark:bg-gray-700 dark:border-gray-600 dark:text-white dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-blue-500 dark:focus:text-white"
                        >
                            {{ item.label }}
                        </router-link>
                    </div>
                </div>
                <div class="flex justify-center lg:justify-end">
                  <router-link :to="{ name: 'pharmasan.ventas.facturacion.facturacion-masiva.historial' }" class="historial text-blue-800 flex justify-end w-min items-center font-bold">
                      <img class="mr-2" src="../../../../../../public/img/icon_historial.svg" alt="Icono historial">
                      Historial
                  </router-link>
                </div>
            </div>
            <div class="w-full">
              <!-- Filtros -->
              <div v-if="(items[2].class === 'active')">
                <div class="w-full grid grid-cols-1 lg:grid-cols-2 gap-2">
                  <div style="height: fit-content;" class="grid grid-cols-2 h-fit gap-4">
                    <div class="flex justify-end items-center">
                      <p>
                        Cliente:
                      </p>
                    </div>
                    <div>
                      <Dropdown
                        v-model="model.CardCode"
                        :options="listadoClientes"
                        optionLabel="Cliente"
                        optionValue="CodCliente"
                        :filter="true"
                        class="w-40 lg:w-8/12 border-gray-300 rounded-md"
                        placeholder="Cliente(s)">
                      </Dropdown>
                    </div>
                    <div class="flex justify-end items-center">
                      <p>
                        No. factura:
                      </p>
                    </div>
                    <div>
                      <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                        <p class="text-xs">{{numeroFactura}}</p>
                      </div>
                    </div>
                  </div>
                  <div style="height: fit-content;" class="grid grid-cols-2 h-fit gap-4">
                    <div style="text-align: right;" class="flex justify-end items-center text-end">
                      <p>
                        Fecha de contabilización:
                      </p>
                    </div>
                    <div>
                      <input type="text" v-model="model.DocDate" onfocus="(this.type='date')" class="appearance-none placeholder-gray-700 block w-40 lg:w-8/12 p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    </div>
                    <div class="flex justify-end items-center">
                      <p>
                        Fecha de entrega:
                      </p>
                    </div>
                    <div>
                      <input type="text" v-model="model.DocDueDate" onfocus="(this.type='date')" class="appearance-none placeholder-gray-700 block w-40 lg:w-8/12 p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    </div>
                    <div class="flex justify-end items-center">
                      <p>
                        Fecha de documento:
                      </p>
                    </div>
                    <div>
                      <input type="text" v-model="model.TaxDate" onfocus="(this.type='date')" class="appearance-none placeholder-gray-700 block w-40 lg:w-8/12 p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                    </div>
                  </div>
                </div>
                <div class="my-4">
                  <DataTable :value="facturaData">
                    <Column headerClass="lg:text-xs" bodyClass="lg:text-xs" field="articulo" header="ARTICULO"></Column>
                    <Column headerClass="lg:text-xs" bodyClass="lg:text-xs"  field="cantidad" header="CANTIDAD"></Column>
                    <Column headerClass="lg:text-xs" bodyClass="lg:text-xs"  field="bodega" header="BODEGA"></Column>
                    <Column headerClass="lg:text-xs" bodyClass="lg:text-xs w-full"  header="PRECIO UNITARIO">
                      <template #body="{data}">
                        <InputNumber v-if="data" inputId="currency-us" class="w-full" inputClass="lg:text-xs w-full" v-model="model.precioUnitario" mode="currency" currency="COP" locale="es-CO" />
                      </template>
                    </Column>
                    <Column headerClass="lg:text-xs" bodyClass="lg:text-xs w-full" header="NORMAS REPARTO">
                      <template #body="{data}">
                        <Dropdown v-if="data"
                          v-model="facturaData.normaReparto"
                          :options="listadoNormasReparto"
                          optionLabel="NormasReparto"
                          optionValue="OcrCode"
                          :filter="true"
                          class="border-gray-300 rounded-md w-full"
                          placeholder="Norma de reparto">
                        </Dropdown>
                      </template>
                    </Column>
                  </DataTable>
                </div>
                <div class="w-full">
                  <div class="w-full lg:w-5/12 flex">
                    <p class="mr-4">Comentarios: </p>
                    <Textarea v-model="model.Comments" class="w-full" rows="4" />
                  </div>
                  <span v-if="menuConvalor && !model.Comments" class="text-xs text-red-600 ">Este campo es requerido</span>
                </div>
                <div class="w-full">
                  <div class="flex justify-end">
                    <div style="text-align: end;">
                      <div class="flex items-center justify-end">
                        <label class="mr-4" for="">Total antes del descuento:</label>
                        <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                          <p class="text-xs">{{$h.formatCurrency(model.precioUnitario)}}</p>
                        </div>
                      </div>
                      <div class="my-2 flex justify-end items-center">
                        <label class="mr-4" for="">Descuento:</label>
                        <InputNumber class="mx-2" mode="decimal" inputId="locale-us" locale="en-US" :minFractionDigits="1" v-model="model.DiscountPercent" inputClass="w-14" />
                        <p class="mr-2">%</p>
                        <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                          <p class="text-xs">{{$h.formatCurrency(model.precioUnitario-(model.precioUnitario-(model.precioUnitario*model.DiscountPercent/100)))}}</p>
                        </div>
                      </div>
                      <div class="my-2 flex justify-end">
                        <label class="mr-4 self-center" for="">Total:</label>
                        <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                          <p class="text-xs">{{ model.DiscountPercent ? $h.formatCurrency(model.precioUnitario-(model.precioUnitario*model.DiscountPercent/100)) : $h.formatCurrency(model.precioUnitario) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full flex justify-end mt-4">
                  <Button class="lg:w-2/12" label="Generar facturación" @click="openModalValidacionOvs" />
                </div>
              </div>
              <div v-else class="grid grid-cols-2 lg:grid-cols-5 gap-2 lg:gap-x-10">
                <div>
                  <Dropdown
                    v-model="bodegaSeleccionada"
                    :options="listadoBodegas"
                    optionLabel="WhsName"
                    @change="cambiarBodega"
                    :filter="true"
                    ref="referenciaInputFiltroBodega"
                    class="w-full border-gray-300 rounded-md"
                    placeholder="Bodega(s)">
                    <template #value="slotProps">
                      <span class="text-sm">
                          {{slotProps.placeholder}}
                      </span>
                    </template>
                  </Dropdown>
                  <div class="w-full flex flex-wrap gap-2 mt-2 max-h-20 overflow-y-auto">
                    <div
                      v-for="(bodega, i) in filtros.CodBodega"
                      :key="i"
                      class="max-h-10 overflow-y-auto">
                      <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                        <button @click="borrarBodegas(bodega)" class="text-blue-800 mr-2">
                          <i style="font-size: 0.6rem" class="pi pi-times"></i>
                        </button>
                        <p style="inline-size: max-content;">{{listadoBodegas.find(a => a.WhsCode === bodega).WhsName}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Dropdown
                    v-model="clienteSeleccionado"
                    :options="listadoClientes"
                    optionLabel="Cliente"
                    ref="referenciaInputFiltroClientes"
                    :filter="true"
                    class="w-full border-gray-300 rounded-md"
                    placeholder="Cliente(s)">
                    <template #value="slotProps">
                      <span class="text-sm">
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  </Dropdown>
                  <div v-if="clienteSeleccionado.Cliente" class="w-full mt-2 max-h-20 overflow-y-auto">
                    <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                      <button @click="clienteSeleccionado = {}" class="text-blue-800 mr-2">
                        <i style="font-size: 0.6rem" class="pi pi-times"></i>
                      </button>
                      <p style="inline-size: max-content;">{{clienteSeleccionado.Cliente}}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <InputText placeholder="Orden(s)" @keydown="validacionOrdenes($event)" @keyup.enter="guardarOrdenes" v-model="ordenSeleccionada" class="w-full text-sm"/>
                  <div class="w-full flex flex-wrap gap-2 mt-2 max-h-20 overflow-y-auto">
                    <div
                      v-for="(orden, i) in filtros.DocNumOrden"
                      :key="i"
                      class="max-h-10 overflow-y-auto">
                      <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                        <button @click="borrarOrdenes(orden)" class="text-blue-800 mr-2">
                          <i style="font-size: 0.6rem" class="pi pi-times"></i>
                        </button>
                        <p style="inline-size: max-content;">{{orden}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="items[1].class === 'active'">
                  <Dropdown
                    v-model="regimenSeleccionado"
                    :options="listadoRegimen"
                    optionLabel="NombreRegimen"
                    class="w-full border-gray-300 rounded-md"
                    placeholder="Regimen">
                    <template #value="slotProps">
                      <span class="text-sm">
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  </Dropdown>
                  <div v-if="regimenSeleccionado.Regimen" class="w-full mt-2 max-h-20 overflow-y-auto">
                    <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                      <button @click="regimenSeleccionado = {}" class="text-blue-800 mr-2">
                        <i style="font-size: 0.6rem" class="
                        pi pi-times"></i>
                      </button>
                      <p style="inline-size: max-content;">{{regimenSeleccionado.NombreRegimen}}</p>
                    </div>
                  </div>
                </div>
                <div v-if="items[1].class === 'active'">
                  <Dropdown
                    v-model="zonaSeleccionada"
                    :options="listadoZonas"
                    class="w-full border-gray-300 rounded-md"
                    optionLabel="DptMunicipio"
                    :filter="true"
                    placeholder="Zona">
                    <template #value="slotProps">
                      <span class="text-sm">
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  </Dropdown>
                  <div v-if="zonaSeleccionada.DptMunicipio" class="w-full mt-2 max-h-20 overflow-y-auto">
                    <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                      <button @click="zonaSeleccionada = {}" class="text-blue-800 mr-2">
                        <i style="font-size: 0.6rem" class="pi pi-times"></i>
                      </button>
                      <p style="inline-size: max-content;">{{zonaSeleccionada.DptMunicipio}}</p>
                    </div>
                  </div>
                </div>
                <div v-if="items[0].class === 'active'" class="flex justify-around items-center" style="height: fit-content;">
                  <p class="text-sm">Mantener orden</p>
                  <InputSwitch v-model="filtros.mantenerOrden" />
                </div>
                <div v-if="items[0].class === 'active'" class="flex justify-around items-center" style="height: fit-content;">
                  <p class="text-sm">Facturar como capita</p>
                  <InputSwitch @change="notificarComoCapita" v-model="filtros.facturarComoCapita" />
                </div>
                <div>
                  <Dropdown
                    v-model="filtros.MotivoAutorizacion"
                    :options="items[0].class === 'active' ? motivosAutorizacionEvento : motivosAutorizacionCapita"
                    class="w-full border-gray-300 rounded-md"
                    optionLabel="Mot_Autoriza"
                    optionValue="Mot_Autoriza"
                    placeholder="Motivo autorización">
                    <template #value="slotProps">
                      <span class="text-sm">
                          {{slotProps.placeholder}}
                      </span>
                  </template>
                  </Dropdown>
                  <div v-if="filtros.MotivoAutorizacion" class="w-full mt-2 max-h-20 overflow-y-auto">
                    <div class="bg-blue-200 p-1 flex rounded-md text-blue-800 text-xs w-min">
                      <button @click="filtros.MotivoAutorizacion = {}" class="text-blue-800 mr-2">
                        <i style="font-size: 0.6rem" class="pi pi-times"></i>
                      </button>
                      <p style="inline-size: max-content;">{{filtros.MotivoAutorizacion}}</p>
                    </div>
                  </div>
                </div>
                <div>
                  <input type="text" v-model="filtros.DocDate[0]" :max="filtros.DocDate[1]" placeholder="Fecha desde" onfocus="(this.type='date')" class="appearance-none placeholder-gray-700 block w-full p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                </div>
                <div>
                  <input type="text" v-model="filtros.DocDate[1]" :min="filtros.DocDate[0]" placeholder="Fecha hasta" onfocus="(this.type='date')" class="appearance-none placeholder-gray-700 block w-full p-2 text-sm font-normal text-gray-900 bg-white bg-clip-padding bg-no-repeat border drop-shadow-none border-gray-300 rounded-lg transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none">
                </div>
                <div v-if="items[1].class === 'active'" class="flex items-center">
                  <p class="text-sm mr-2">Intranet V1</p>
                  <InputSwitch v-model="filtros.versionIntranet" />
                  <p class="text-sm ml-2">V2</p>
                </div>
                <div class="grid grid-cols-2 gap-2 hidden xl:flex" style="height: fit-content;">
                  <Button @click="buscarFiltros" class="w-full rounded-md" icon="pi pi-search" label="Buscar" />
                  <Button @click="limpiarFiltros" class="bg-white w-full text-gray-600 font-medium border-0 rounded-md" label="Limpiar" />
                </div>
                <div class="xl:hidden">
                  <Button @click="buscarFiltros" class="w-full rounded-md" icon="pi pi-search" label="Buscar" />
                </div>
                <div class="xl:hidden">
                  <Button @click="limpiarFiltros" class="bg-white w-full text-gray-600 font-medium border-0 rounded-md" label="Limpiar" />
                </div>
              </div>
            </div>
            <router-view :cliente="clienteSeleccionado" :dataMenu="items[1].class === 'active' ? dataMenuCapita : menuCapitaData" class="w-100 mt-4" />
        </div>
        <div class="flex">
          <button v-if="(items[0].class === 'active' && filtros.facturarComoCapita)" style="height: fit-content;" @click="menuEvento = !menuEvento" class="button-responsive ml-2 p-2 rounded-lg bg-blue-300">
              <svg v-if="menuEvento" class="w-4 h-4 animate__animated animate__slideInLeft" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
              <svg v-else class="w-4 h-4 animate__animated animate__slideInLeft" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
          </button>
          <!-- datos de facturacion evento capita -->
          <div v-if="menuEvento && items[0].class === 'active' && filtros.facturarComoCapita" >
            <div class="mx-2 bg-white rounded-md">
              <div class="p-6 space-y-6">
                <div class="text-center">
                  <p class="font-bold text-blue-800">
                    Ingresar datos de la factura
                  </p>
                </div>
                <div class="mb-2">
                  <Dropdown class="w-full border-gray-300 rounded-md" v-model="menuCapitaData.localizacion" :options="listadoZonas" optionLabel="DptMunicipio" :filter="true" placeholder="Localizacion"></Dropdown>
                  <span v-if="abrirMenuEventoCapita && !menuCapitaData.localizacion.Departamento" class="text-xs text-red-600">Este campo es requerido</span>
                </div>
                <div class="mb-2">
                  <Dropdown
                    class="w-full border-gray-300 rounded-md"
                    v-model="menuCapitaData.mes"
                    :options="meses"
                    optionLabel="name"
                    optionValue="name"
                    :filter="true"
                    placeholder="Mes">
                  </Dropdown>
                  <span v-if="abrirMenuEventoCapita && !menuCapitaData.mes" class="text-xs text-red-600">Este campo es requerido</span>
                </div>
                <div class="mb-2">
                  <textarea required v-model="menuCapitaData.comentario"
                    class="text-sm block placeholder-gray-700 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    name="comentario" id="comentarioFactura" cols="30" rows="10"
                    placeholder="Comentario"></textarea>
                  <span v-if="abrirMenuEventoCapita && !menuCapitaData.comentario" class="text-xs text-red-600">Este campo es requerido</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <button v-if="(items[1].class === 'active')" style="height: fit-content;" @click="(menuCapita = !menuCapita)" class="button-responsive ml-2 p-2 rounded-lg bg-blue-300">
              <svg v-if="menuCapita" class="w-4 h-4 animate__animated animate__slideInLeft" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
              <svg v-else class="w-4 h-4 animate__animated animate__slideInLeft" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
          </button>
          <!-- datos facturacion capita -->
          <div v-if="menuCapita && items[1].class === 'active'" >
            <div class="mx-2 bg-white rounded-md">
              <div class="p-6 space-y-6">
                <div class="text-center">
                  <p class="font-bold text-blue-800">
                    Ingresar datos de la factura
                  </p>
                </div>
                <div class="mb-2">
                  <Dropdown class="w-full border-gray-300 rounded-md" v-model="dataMenuCapita.localizacion" :options="listadoZonas" optionLabel="DptMunicipio" :filter="true" placeholder="Localizacion"></Dropdown>
                  <span v-if="abrirMenuCapita && !dataMenuCapita.localizacion.Departamento" class="text-xs text-red-600">Este campo es requerido</span>
                </div>
                <div class="mb-2">
                  <Dropdown
                    class="w-full border-gray-300 rounded-md"
                    v-model="dataMenuCapita.MesCapita"
                    :options="meses"
                    optionLabel="name"
                    optionValue="name"
                    :filter="true"
                    placeholder="Mes">
                  </Dropdown>
                  <span v-if="abrirMenuCapita && !dataMenuCapita.MesCapita" class="text-xs text-red-600">Este campo es requerido</span>
                </div>
                <div class="mb-2">
                  <Dropdown class="w-full border-gray-300 rounded-md" v-model="dataMenuCapita.U_PHR_RegimenF" optionLabel="NombreRegimen" optionValue="Regimen" :options="listadoRegimenF" :filter="true" placeholder="Regimen"></Dropdown>
                  <span v-if="abrirMenuCapita && !dataMenuCapita.U_PHR_RegimenF" class="text-xs text-red-600">Este campo es requerido</span>
                </div>
                <div class="mb-2">
                  <textarea
                    v-model="dataMenuCapita.comentario_factura"
                    class="text-xs block placeholder-gray-700 p-2  w-full text-gray-900 rounded-lg border border-gray-300"
                    name="comentario" id="comentarioFactura" cols="30" rows="10"
                    placeholder="Comentario"></textarea>
                    <span v-if="abrirMenuCapita && !dataMenuCapita.comentario_factura" class="text-xs text-red-600">Este campo es requerido</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex">
          <button v-if="(items[2].class === 'active')" style="height: fit-content;" @click="(menuConvalor = !menuConvalor)" class="button-responsive hidden lg:flex ml-2 p-2 rounded-lg bg-blue-300">
              <svg v-if="menuConvalor" class="w-4 h-4 animate__animated animate__slideInLeft" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
              <svg v-else class="w-4 h-4 animate__animated animate__slideInLeft" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path></svg>
          </button>
          <!-- datos de facturacion con valor -->
          <div v-if="menuConvalor && items[2].class === 'active'" >
            <div style="inline-size: max-content;" class="bg-white rounded-md px-4 py-2 ml-2 gap-2 text-end mt-4 lg:mt-0">
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Modalidad de contrato:
                </p>
                <Dropdown
                  v-model="model.U_PHR_ModContrato"
                  :options="listadoModalidadesContrato"
                  optionLabel="U_PHR_ModContratoNombre"
                  optionValue="U_PHR_ModContrato"
                  :filter="true"
                  panelClass="lg:text-xs"
                  class="w-40 border-gray-300 rounded-md text-xs"
                  placeholder="Seleccione">
                </Dropdown>
              </div>
              <span v-if="menuConvalor && !model.U_PHR_ModContrato" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Motivo autorizacion:
                </p>
                <Dropdown
                  v-model="model.U_PHR_MotAutoriza"
                  :options="listadoMotivosAutorizacion"
                  optionLabel="Mot_Autoriza"
                  optionValue="U_PHR_MotAutoriza"
                  panelClass="lg:text-xs"
                  :filter="true"
                  class="w-40 border-gray-300 rounded-md"
                  placeholder="Seleccione">
                </Dropdown>
              </div>
              <span v-if="menuConvalor && !model.U_PHR_MotAutoriza" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Mes servicio capitacion:
                </p>
                <Dropdown
                  v-model="model.U_PHR_MesCapita"
                  :options="meses"
                  optionLabel="name"
                  optionValue="name"
                  :filter="true"
                  panelClass="lg:text-xs"
                  class="w-40 border-gray-300 rounded-md"
                  placeholder="Seleccione">
                </Dropdown>
              </div>
              <span v-if="menuConvalor && !model.U_PHR_MesCapita" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Contrato autorizacion:
                </p>
                <Dropdown
                  v-model="model.U_PHR_Cn_Aut"
                  :options="listadoContratoAutorizacion"
                  optionLabel="U_PHR_Cn_AutNombre"
                  opionValue="U_PHR_Cn_Aut"
                  :filter="true"
                  class="w-40 border-gray-300 rounded-md"
                  panelClass="lg:text-xs"
                  placeholder="Seleccione">
                </Dropdown>
              </div>
              <span v-if="menuConvalor && !model.U_PHR_Cn_Aut" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Codigo municipio:
                </p>
                <Dropdown
                  v-model="localizacionFacturaValor"
                  :options="listadoZonas"
                  optionLabel="DptMunicipio"
                  :filter="true"
                  class="w-40 border-gray-300 rounded-md"
                  panelClass="lg:text-xs"
                  placeholder="Seleccione">
                </Dropdown>
              </div>
              <span v-if="menuConvalor && !localizacionFacturaValor" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Departamento afiliación:
                </p>
                <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                  <p v-if="localizacionFacturaValor.Departamento" class="text-xs">{{localizacionFacturaValor.Departamento}}</p>
                </div>
              </div>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Municipio capitación:
                </p>
                <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                  <p v-if="localizacionFacturaValor.Municipio" class="text-xs">{{localizacionFacturaValor.Municipio}}</p>
                </div>
              </div>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  No. contrato:
                </p>
                <InputText type="text" class="w-40" v-model="model.U_PHR_NumCtoF" />
              </div>
              <span v-if="menuConvalor && !model.U_PHR_NumCtoF" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Regimen:
                </p>
                <Dropdown
                  v-model="model.U_PHR_RegPac"
                  :options="listadoRegimen"
                  optionLabel="NombreRegimen"
                  optionValue="Regimen"
                  :filter="true"
                  class="w-40 border-gray-300 rounded-md"
                  panelClass="lg:text-xs"
                  placeholder="Regimen">
                </Dropdown>
              </div>
              <span v-if="menuConvalor && !model.U_PHR_RegPac" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Estado factura cartera:
                </p>
                <div class="bg-gray-300 rounded-md p-2 w-40">
                  <p class="text-xs">7 - ENVIADO A RADICACION</p>
                </div>
              </div>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Usuarios capitación mes:
                </p>
                <InputNumber inputId="integeronly" inputClass="w-40 text-xs" v-model="model.U_PHR_UsuarCapita" />
              </div>
              <span v-if="menuConvalor && !model.U_PHR_UsuarCapita" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Valor unitario:
                </p>
                <InputNumber inputClass="w-40 text-xs" inputId="currency-us" v-model="model.U_PHR_VlrUntF" mode="currency" currency="COP" locale="es-CO" />
              </div>
              <span v-if="menuConvalor && !model.U_PHR_VlrUntF" class="text-xs text-red-600 float-right">Este campo es requerido</span>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Creado por:
                </p>
                <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                  <p class="text-xs">{{user.full_name}}</p>
                </div>
              </div>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Actualizado por:
                </p>
                <div class="bg-gray-300 rounded-md p-2 w-40 h-8">
                  <p class="text-xs">{{user.full_name}}</p>
                </div>
              </div>
              <div class="flex justify-end w-full items-center gap-2 my-1">
                <p>
                  Comentario:
                </p>
                <Textarea class="w-40" v-model="model.U_PHR_Desc1" rows="3" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog :closable="false" header="Actualizar cantidad Ov por JSON" v-model:visible="displayNumeroOrdenesJson" :style="{width: '30vw'}" :modal="true">
          <p class="text-xs mb-4">Esta acción actualizará la cantidad de ordenes que conformara un Json al facturar como capita, recuerde que cada Json conformará una factura</p>
          <InputNumber id="integeronly" class="w-full" :max="100" v-model="cantidadOrdenesJson" />
          <span class="text-gray-400 italic text-xs">La cantidad maxima es de 100</span>
          <template #footer>
              <Button label="Cancelar" icon="pi pi-times" @click="cerrarModalCantOvJson" class="p-button-text"/>
              <Button label="Guardar" icon="pi pi-check" @click="actualizarCantidadFacturasJson" autofocus />
          </template>
      </Dialog>
    </section>
</template>
<script>
    import GenerarFacturasService from '../../services/generarFacturas.service'
    import BodegasServices from '../../services/bodegas.service'
    import ClientesServices from '../../services/clientes.service'
    import MotivosAutorizacionService from '../../services/motivos_autorizacion.service'
    import ContratoAutorizacionServices from '../../services/contrato_autorizacion.service'
    import ZonificacionService from '../../services/zonificacion.service'
    import FacturasValorService from '../../services/generar_factura_valor.service'
    import NormasRepartoServices from '../../services/normas_reparto.service'
    import FacturaNumeroService from '../../services/factura_numero.service'
    import { useStore } from 'vuex'
    import RegimenServices from '../../services/regimen.service'
    import ModalidadContratoFacturaValorServices from '../../services/modalidad_contrato_factura_valor.service'
    import facturacionStore from '../../store/facturacion.store'
    import '../../../../../../node_modules/animate.css/animate.css'
    import { onMounted, computed, ref, watch } from 'vue'
    import { useRoute } from 'vue-router'
    import { useField, useForm } from 'vee-validate'
    import { string, object, number, date } from 'yup'
    import Swal from 'sweetalert2'
    import dayjs from 'dayjs'
    export default {
      name: 'Menufacturacion',
      setup () {
        // Services
        const _BodegasServices = ref(new BodegasServices())
        const _ClientesServices = ref(new ClientesServices())
        const _MotivosAutorizacionService = ref(new MotivosAutorizacionService())
        const _RegimenServices = ref(new RegimenServices())
        const _Zonas = ref(new ZonificacionService())
        const _Facturar = ref(new FacturasValorService())
        const _FacturaNumeroService = ref(new FacturaNumeroService())
        const _NormasRepartoServices = ref(new NormasRepartoServices())
        const _ModalidadContratoFacturaValorServices = ref(new ModalidadContratoFacturaValorServices())
        const _ContratoAutorizacionServices = ref(new ContratoAutorizacionServices())
        const route = useRoute()
        const store = useStore()
        const _GenerarFacturasService = ref(new GenerarFacturasService())
        // References
        const listadoBodegas = ref([])
        const bodegaSeleccionada = ref()
        const listadoClientes = ref([])
        const clienteSeleccionado = ref({})
        const objetoPrueba = ref({})
        const ordenSeleccionada = ref()
        const motivosAutorizacionCapita = ref([])
        const motivosAutorizacionEvento = ref([])
        const listadoMotivosAutorizacion = ref([])
        const listadoRegimen = ref([])
        const listadoRegimenF = ref([])
        const listadoModalidadesContrato = ref([])
        const listadoContratoAutorizacion = ref([])
        const listadoZonas = ref([])
        const listadoNormasReparto = ref([])
        const zonaSeleccionada = ref('')
        const localizacionFacturaValor = ref({})
        const botonMenuEvento = ref(false)
        const menuEvento = ref(false)
        const menuEventoCapita = ref(false)
        const menuCapita = ref(false)
        const menuConvalor = ref(false)
        const tipoFacturacion = ref('')
        const referenciaInputFiltroBodega = ref('')
        const referenciaInputFiltroClientes = ref('')
        const numeroFactura = ref(0)
        const localizacionFacturarCapita = ref({})
        const cantidadOrdenesJson = ref(0)
        const regimenSeleccionado = ref({})
        const user = store.getters['auth/getPeople']
        const displayNumeroOrdenesJson = ref(false)
        const menuCapitaData = ref({
          localizacion: {},
          mes: '',
          comentario: ''
        })
        const dataMenuCapita = ref({
          MesCapita: '',
          comentario_factura: '',
          U_PHR_RegimenF: '',
          localizacion: {}
        })
        const facturaData = ref([{
          articulo: 'CAPITA001',
          cantidad: 1,
          bodega: 'BOD0001',
          precioUnitario: 0,
          normaReparto: ''
        }])
        const filtros = ref({
          versionIntranet: false,
          CodBodega: [],
          CodCliente: [],
          mantenerOrden: true,
          DocNumOrden: [],
          MotivoAutorizacion: '',
          Regimen: '',
          facturarComoCapita: false,
          U_PHR_CodMunicipio: '',
          DocDate: [
            dayjs().format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD')
          ]
        })
        const meses = ref([
          { name: 'ENERO' },
          { name: 'FEBRERO' },
          { name: 'MARZO' },
          { name: 'ABRIL' },
          { name: 'MAYO' },
          { name: 'JUNIO' },
          { name: 'JULIO' },
          { name: 'AGOSTO' },
          { name: 'SEPTIEMBRE' },
          { name: 'OCTUBRE' },
          { name: 'NOVIEMBRE' },
          { name: 'DICIEMBRE' }
        ])
        // Computed
        const abrirMenuEventoCapita = computed(() => facturacionStore.getters._abrirMenuEventoCapita)
        const abrirMenuCapita = computed(() => facturacionStore.getters._abrirMenuCapita)
        const abrirMenuConvalor = computed(() => facturacionStore.getters._abrirMenuConvalor)
        const items = computed(() => {
          return [
              {
                id: 'facturacion-evento',
                to: { name: 'pharmasan.ventas.facturacion.facturacion-masiva.evento', params: { type: 'evento' } },
                class: getActive([
                    'pharmasan.ventas.facturacion.facturacion-masiva.evento'
                ]),
                label: 'Evento'
              },
              {
                id: 'facturacion-capitacion',
                to: { name: 'pharmasan.ventas.facturacion.facturacion-masiva.capita', params: { type: 'capita' } },
                class: getActive([
                    'pharmasan.ventas.facturacion.facturacion-masiva.capita'
                ]),
                label: 'Capita'
              },
              {
                id: 'facturacion-valor',
                to: { name: 'pharmasan.ventas.facturacion.facturacion-masiva.factura-valor', params: { type: 'valor' } },
                class: getActive([
                    'pharmasan.ventas.facturacion.facturacion-masiva.factura-valor'
                ]),
                label: 'Factura con valor'
              }
            ]
        })
        const validationSchema = object().shape({
          U_PHR_ModContrato: string().required().label('Este campo es requerido'),
          U_PHR_MotAutoriza: string().required().label('Motivo de autorización'),
          U_PHR_MesCapita: string().required().label('Mes capitado'),
          U_PHR_Cn_Aut: string().required().label('Contrato autorización'),
          U_PHR_NumCtoF: string(),
          U_PHR_MunicipioF: number().required().label('Campo requerido'),
          U_PHR_CodMunicipio: string().required().label('Campo requerido'),
          U_PHR_RegPac: string().required().label('Regimen'),
          U_PHR_UsuarCapita: string().required().label('Usuarios'),
          U_PHR_VlrUntF: number().required().label('Valor unitario'),
          U_PHR_EstadoFac: string(),
          CardCode: string().required().label('Este campo es requerido'),
          DocDate: date().required().label('Este campo es requerido'),
          DocDueDate: date().required().label('Este campo es requerido'),
          TaxDate: date().required().label('Este campo es requerido'),
          Comments: string().required().label('Este campo es requerido'),
          DiscountPercent: number().required().label('Este campo es requerido'),
          DocumentLine: object().shape({
            monto_linea_capita: number().required().label('Este campo es requerido'),
            ItemCode: string().required().label('Este campo es requerido'),
            PrcCode: number().required().label('Este campo es requerido'),
            Quantity: number().required().label('Este campo es requerido'),
            WhsCode: string().required().label('Este campo es requerido')
          })
        })

        const { values: model, errors, handleSubmit, handleReset } = useForm({
          validationSchema
        })

        const openModalValidacionOvs = () => {
          const objeto = model
          delete objeto.U_PHR_Desc1
          if (Object.values(objeto).some(a => !a)) {
            alertar('Ingrese todos los datos de la factura')
            facturacionStore.commit('setAbrirMenuConvalor', true)
          } else {
            envioFacturar()
          }
        }
        const alertar = (mensaje, tipo) => {
          const icon = tipo === 1 ? 'warning' : 'warning'
          Swal.fire({
            icon: icon,
            title: ` <span style="line-height: normal;">${mensaje}</span>`
          })
        }

        const envioFacturar = () => {
          facturarEventoCapita()
          const valores = {
            CardCode: model.CardCode,
            DocDate: model.DocDate,
            DocDueDate: model.DocDueDate,
            TaxDate: model.TaxDate,
            Comments: model.Comments,
            // DiscountPercent: model.DiscountPercent,
            DiscPrcnt: model.DiscountPercent,
            U_PHR_CodMunicipio: model.U_PHR_CodMunicipio,
            U_PHR_DptoAfiliacion: model.U_PHR_DptoAfiliacion,
            U_PHR_Desc1: model.U_PHR_Desc1,
            U_PHR_EstadoFac: model.U_PHR_EstadoFac,
            U_PHR_MesCapita: model.U_PHR_MesCapita,
            U_PHR_ModContrato: model.U_PHR_ModContrato,
            U_PHR_MotAutoriza: model.U_PHR_MotAutoriza,
            U_PHR_MunicipioF: model.U_PHR_MunicipioF,
            U_PHR_NumCtoF: model.U_PHR_NumCtoF,
            U_PHR_RegPac: model.U_PHR_RegPac,
            U_PHR_UsuarCapita: model.U_PHR_UsuarCapita,
            U_PHR_VlrUntF: model.U_PHR_VlrUntF,
            U_PHR_Cn_Aut: model.U_PHR_Cn_Aut.U_PHR_Cn_Aut,
            U_PHR_Bodega: 'BOD0001',
            DocumentLine: {
              monto_linea_capita: model.precioUnitario,
              CostingCode: model.DocumentLine.CostingCode,
              WhsCode: 'BOD0001',
              Quantity: 1,
              ItemCode: 'CAPITA001'
            }
          }
          _GenerarFacturasService.value.valor(valores).then(({ data }) => {
            if (data.error) {
              Swal.fire(
                'Error',
                'A ocurrido un error al facturar',
                'error'
              ).then(() => {
              })
            } else {
              Swal.fire(
                'Guardado',
                'Las ordenes se estan procesando, por favor revise su historial para ver el seguimiento',
                'success'
              ).then(() => {
                handleReset()
                facturaData.value.normaReparto = ''
                localizacionFacturaValor.value = {}
              })
            }
          })
        }

        useField('U_PHR_ModContrato', null, {
          initialValue: ''
        })
        useField('U_PHR_MesCapita', null, {
          initialValue: ''
        })
        useField('U_PHR_MotAutoriza', null, {
          initialValue: ''
        })
        useField('U_PHR_RegPac', null, {
          initialValue: ''
        })
        useField('U_PHR_UsuarCapita', null, {
          initialValue: ''
        })
        useField('U_PHR_NumCtoF', null, {
          initialValue: ''
        })
        useField('U_PHR_Cn_Aut', null, {
          initialValue: ''
        })
        useField('U_PHR_VlrUntF', null, {
          initialValue: 0
        })
        useField('U_PHR_EstadoFac', null, {
          initialValue: 7
        })
        useField('U_PHR_Desc1')
        useField('CardCode', null, {
          initialValue: ''
        })
        useField('DocDate', null, {
          initialValue: dayjs().format('DD/MM/YYYY')
        })
        useField('DocDueDate', null, {
          initialValue: dayjs().format('DD/MM/YYYY')
        })
        useField('TaxDate', null, {
          initialValue: dayjs().format('DD/MM/YYYY')
        })
        useField('Comments', null, {
          initialValue: ''
        })
        useField('DocumentLine', null, {
          initialValue: {
            monto_linea_capita: 122,
            ItemCode: 'CAPITA001',
            PrcCode: '',
            Quantity: 0,
            WhsCode: 'BOD0001'
          }
        })
        // Data
        const listarBodegas = () => {
          listadoBodegas.value = []
          _BodegasServices.value.getBodegas().then(({ data }) => {
            listadoBodegas.value = data
          })
        }
        const listarClientes = () => {
          _ClientesServices.value.getClientes().then(({ data }) => {
            listadoClientes.value = data
          })
        }
        const listarMotivosAutorizacion = () => {
          _MotivosAutorizacionService.value.get().then(({ data }) => {
            listadoMotivosAutorizacion.value = data
            for (const motivo of data) {
              if (motivo.Mot_Autoriza.includes('CAPITA')) {
                motivosAutorizacionCapita.value.push(motivo)
              } else {
                motivosAutorizacionEvento.value.push(motivo)
              }
            }
          })
        }
        const listarRegimen = () => {
          _RegimenServices.value.get().then(({ data }) => {
            listadoRegimen.value = data
            facturacionStore.commit('setListadoRegimen', data)
          })
          _RegimenServices.value.getRF().then(({ data }) => {
            listadoRegimenF.value = data
            facturacionStore.commit('setListadoRegimenF', data)
          })
        }
        const listarZonas = () => {
          _Zonas.value.get().then(({ data }) => {
            listadoZonas.value = data
          })
        }
        const listarNormasReparto = () => {
          _NormasRepartoServices.value.getNormasReparto().then(({ data }) => {
            listadoNormasReparto.value = data
          })
        }
        const listarModalidadContrato = () => {
          _ModalidadContratoFacturaValorServices.value.get().then(({ data }) => {
            listadoModalidadesContrato.value = data
          })
        }
        const listarContratosAutorizacion = () => {
          _ContratoAutorizacionServices.value.getContratoAutorizacion().then(({ data }) => {
            listadoContratoAutorizacion.value = data
          })
        }
        const traerUltimaFactura = () => {
          _FacturaNumeroService.value.getFacturaNumero().then(({ data }) => { numeroFactura.value = data[0].DocNum + 1 })
        }
        // Methods
        const validacionOrdenes = (e) => {
          if (/[^0-9\s]/.test(e.key)) {
            console.log('entro')
            if (e.key === 'Backspace' || e.key === 'v' || e.key === 'Control') {
              if (e.key === 'v' && !e.ctrlKey) {
                e.preventDefault()
              }
            } else {
              e.preventDefault()
            }
          }
        }
        const cambiarBodega = () => {
          if (!filtros.value.CodBodega.some(a => a === bodegaSeleccionada.value.WhsCode)) {
            filtros.value.CodBodega.push(bodegaSeleccionada.value.WhsCode)
          }
        }
        const borrarBodegas = (bodega) => {
          const indexFiltro = filtros.value.CodBodega.findIndex(a => a === bodega)
          filtros.value.CodBodega.splice(indexFiltro, 1)
        }
        const borrarCliente = () => {
          clienteSeleccionado.value = {}
          filtros.value.CodCliente = []
        }
        const guardarOrdenes = () => {
          for (const orden of ordenSeleccionada.value.split(' ')) {
            filtros.value.DocNumOrden.length ? (
              orden === '' ? filtros.value.DocNumOrden.push() : (
                filtros.value.DocNumOrden.some(a => a === orden) ? filtros.value.DocNumOrden.push() : filtros.value.DocNumOrden.push(orden)
              )
            )
            : filtros.value.DocNumOrden.push(orden)
          }
          ordenSeleccionada.value = ''
        }
        const borrarOrdenes = (orden) => {
          const index = filtros.value.DocNumOrden.findIndex(a => a === orden)
          filtros.value.DocNumOrden.splice(index, 1)
        }
        const limpiarFiltros = () => {
          filtros.value.CodBodega = []
          filtros.value.CodCliente = []
          filtros.value.mantenerOrden = true
          filtros.value.DocNumOrden = []
          filtros.value.MotivoAutorizacion = ''
          filtros.value.Regimen = ''
          filtros.value.U_PHR_CodMunicipio = ''
          filtros.value.facturarComoCapita = false
          referenciaInputFiltroBodega.value.filterValue = ''
          referenciaInputFiltroClientes.value.filterValue = ''
          filtros.value.DocDate = [
            dayjs().format('YYYY-MM-DD'),
            dayjs().format('YYYY-MM-DD')
          ]
          filtros.value.limpiar = true
          regimenSeleccionado.value = {}
          clienteSeleccionado.value = {}
          zonaSeleccionada.value = {}
          const objetoFiltros = {
            ...filtros.value
          }
          if (regimenSeleccionado.value.Regimen) {
            objetoFiltros.Regimen = regimenSeleccionado.value.Regimen
          }
          if (zonaSeleccionada.value.U_PHR_CodMunicipio) {
            objetoFiltros.U_PHR_CodMunicipio = zonaSeleccionada.value.U_PHR_CodMunicipio
          }
          objetoFiltros.CodCliente = []
          if (clienteSeleccionado.value.CodCliente) {
            objetoFiltros.CodCliente.push([clienteSeleccionado.value.CodCliente])
          }
          facturacionStore.commit('setFiltros', objetoFiltros)
          notificarComoCapita()
        }
        const notificarComoCapita = () => {
          facturacionStore.commit('setComoCapita', filtros.value.facturarComoCapita)
        }
        const buscarFiltros = () => {
          const objetoFiltros = {
            ...filtros.value
          }
          if (regimenSeleccionado.value.Regimen) {
            objetoFiltros.Regimen = regimenSeleccionado.value.Regimen
          }
          if (zonaSeleccionada.value.U_PHR_CodMunicipio) {
            objetoFiltros.U_PHR_CodMunicipio = zonaSeleccionada.value.U_PHR_CodMunicipio
          }
          objetoFiltros.CodCliente = []
          if (clienteSeleccionado.value.CodCliente) {
            objetoFiltros.CodCliente.push([clienteSeleccionado.value.CodCliente])
          }
          objetoFiltros.limpiar = false
          facturacionStore.commit('setFiltros', objetoFiltros)
        }
        const getActive = (routeNames = []) => {
          menuEvento.value = false
          if (routeNames.includes(route.name)) {
            filtros.value.CodBodega = []
            filtros.value.CodCliente = []
            clienteSeleccionado.value = {}
            zonaSeleccionada.value = {}
            regimenSeleccionado.value = {}
            filtros.value.mantenerOrden = true
            filtros.value.DocNumOrden = []
            filtros.value.MotivoAutorizacion = ''
            filtros.value.Regimen = ''
            filtros.value.U_PHR_CodMunicipio = ''
            filtros.value.facturarComoCapita = false
            filtros.value.DocDate = [
              dayjs().format('YYYY-MM-DD'),
              dayjs().format('YYYY-MM-DD')
            ]
            filtros.value.limpiar = true
            facturacionStore.commit('setFiltros', filtros.value)
            tipoFacturacion.value = route.name
            facturacionStore.commit('setDataFacturaCapita',
            {
              MesCapita: '',
              comentario_factura: '',
              U_PHR_RegimenF: '',
              localizacion: {}
            })
          return 'active'
          }
          return ''
        }
        const facturarEventoCapita = () => {
          model.U_PHR_CodMunicipio = localizacionFacturaValor.value.U_PHR_CodMunicipio
          model.U_PHR_DptoAfiliacion = localizacionFacturaValor.value.Departamento
          model.U_PHR_MunicipioF = localizacionFacturaValor.value.Municipio.substr(0, 14)
          model.U_PHR_EstadoFac = '7'
          model.DocumentLine.ItemCode = 'CAPITA001'
          model.DocumentLine.Quantity = 1
          model.DocumentLine.WhsCode = 'BOD0001'
          model.DocumentLine.monto_linea_capita = facturaData.value.precioUnitario
          model.DocumentLine.CostingCode = facturaData.value.normaReparto
          validarFormulario()
        }
        const validarFormulario = handleSubmit((values) => {
          Swal.fire({
              title: 'Procesando',
              showConfirmButton: false,
              onBeforeOpen: () => {
                  Swal.showLoading()
              }
          })
          _Facturar.value.getFacturasValor(values).then(() => {
              Swal.fire({
                  text: 'Guardado',
                  icon: 'success'
              }).then((res) => {
                  console.log(res)
                  location.reload()
              })
          })
        })
        const cerrarModalCantOvJson = () => {
          displayNumeroOrdenesJson.value = false
          cantidadOrdenesJson.value = 0
        }
        const actualizarCantidadFacturasJson = () => {
          _GenerarFacturasService.value.actualizarCantidadOrdenesPorJson({ cantidad: cantidadOrdenesJson.value }).then(() => {
            Swal.fire(
              'Actualizado!',
              'La cantidad de ordenes por Json se actualizo con éxito!',
              'success'
            ).then(() => {
              cerrarModalCantOvJson()
            })
          })
        }
        watch(dataMenuCapita.value, (n, v) => {
          if (n) {
            facturacionStore.commit('setDataFacturaCapita', dataMenuCapita.value)
          }
        })
        watch(menuCapitaData.value, (n, v) => {
          if (n) {
            facturacionStore.commit('setDataFacturarEventoCapita', n)
          }
        })
        watch(abrirMenuCapita, (n, v) => {
          menuCapita.value = n
        })
        watch(abrirMenuEventoCapita, (n, v) => {
          menuEvento.value = n
        })
        watch(abrirMenuConvalor, (n, v) => {
          menuConvalor.value = n
        })
        onMounted(() => {
          listarBodegas()
          listarClientes()
          listarMotivosAutorizacion()
          listarRegimen()
          listarZonas()
          listarModalidadContrato()
          listarContratosAutorizacion()
          traerUltimaFactura()
          listarNormasReparto()
        })
        return {
          items,
          envioFacturar,
          listadoBodegas,
          bodegaSeleccionada,
          borrarBodegas,
          listarNormasReparto,
          limpiarFiltros,
          buscarFiltros,
          listadoClientes,
          clienteSeleccionado,
          borrarCliente,
          filtros,
          guardarOrdenes,
          ordenSeleccionada,
          borrarOrdenes,
          motivosAutorizacionCapita,
          motivosAutorizacionEvento,
          tipoFacturacion,
          listadoRegimen,
          listadoRegimenF,
          listadoZonas,
          botonMenuEvento,
          menuEvento,
          menuCapitaData,
          facturaData,
          menuEventoCapita,
          menuConvalor,
          meses,
          errors,
          validarFormulario,
          model,
          objetoPrueba,
          zonaSeleccionada,
          regimenSeleccionado,
          listadoModalidadesContrato,
          listadoMotivosAutorizacion,
          listadoContratoAutorizacion,
          localizacionFacturaValor,
          facturarEventoCapita,
          user,
          numeroFactura,
          listadoNormasReparto,
          menuCapita,
          dataMenuCapita,
          localizacionFacturarCapita,
          notificarComoCapita,
          abrirMenuCapita,
          abrirMenuEventoCapita,
          abrirMenuConvalor,
          alertar,
          openModalValidacionOvs,
          cambiarBodega,
          referenciaInputFiltroBodega,
          referenciaInputFiltroClientes,
          validacionOrdenes,
          actualizarCantidadFacturasJson,
          displayNumeroOrdenesJson,
          cerrarModalCantOvJson,
          cantidadOrdenesJson
        }
      }

    }
</script>
<style scoped>
::-webkit-scrollbar {
  width: 0.4rem;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgb(189, 189, 189);
  border-radius: 9999px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
::-webkit-input-placeholder { /* Edge */
  color: gray;
}
::v-deep(.p-dropdown .p-dropdown-label) {
  font-size: 0.75rem !important;
}
:global(.swal2-container) {z-index: 1000000 !important;}
</style>
