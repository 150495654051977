import http from '@/libs/http'
export default class MotivosAutorizacionService {
  getMotivosAutorizacion () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/motivos_autorizacion_factura_valor`)
  }

  get () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/mot-autoriza`)
  }
}
