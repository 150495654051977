import http from '@/libs/http'
export default class ZonificacionService {
  getZonas () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/zonificacion`)
  }

  get () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/zonas`)
  }
}
