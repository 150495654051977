import http from '@/libs/http'
export default class ModalidadContratoFacturaValorServices {
  getModalidadContratoFacturaValor () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/modalidad_contrato_factura_valor`)
  }

  get () {
    return http.get(`${process.env.VUE_APP_MICROSERVICE_API_FACTURACION}/modalidad_contrato`)
  }
}
